import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from 'react';
import Moment from "react-moment";
import Ato from '../../../../vector/ato.png';
import House from '../../../../vector/casa.png';
import { getCrewStandbyBr, updateCrewStandbyBr } from "../../../../firebase";
import GenericSnackbar from "../../../GenericSnackbar/GenericSnackbar";
import "./CrewStandbyMaintainerBrasil.css";

import {
  CREW_STANDBY_BR_AM_GROUP,
  CREW_STANDBY_BR_PM_GROUP,
  CREW_STANDBY_BR,               // ["320SAO","320RIO","320BSB","320POA","787SAO","777SAO"]
  CREW_STANDBY_BR_MANDO_CABINA,  // ["Mando","Cabina","Mando","Cabina","Mando","Cabina","Mando","Cabina"]
  CREW_STANDBY_BRA_MAINTEN_AM,   // Datos AM
  CREW_STANDBY_BRA_MAINTEN_PM    // Datos PM
} from '../../../../utils/Constants';

function CrewStandbyMaintainerBrasil(props) {
  const [snackbar, setSnackbar] = useState('');
  const [crewStandbyAm, setCrewStandbyAm] = useState(CREW_STANDBY_BRA_MAINTEN_AM);
  const [crewStandbyPm, setCrewStandbyPm] = useState(CREW_STANDBY_BRA_MAINTEN_PM);
  const [lastState, setLastState] = useState();

  /**
   * onChangeInput
   * Cambia un valor dentro del estado. Debemos saber si es AM o PM,
   * en base al horario, o en base a la tabla, etc.
   * En este ejemplo, asumimos que si el horario está en AM_GROUP,
   * modificamos crewStandbyAm; si está en PM_GROUP, crewStandbyPm.
   * O si la tabla es la AM, usamos crewStandbyAm, si la tabla es la PM, crewStandbyPm.
   *
   * @param {SyntheticEvent} e
   * @param {string} flota  => "320SAO", "320RIO", etc.
   * @param {string} horario => "00:00 - 03:00", ...
   * @param {string} rol => "Mando" o "Cabina"
   * @param {string} minOrMax => "Min" o "Max"
   * @param {string} atoOCasa => "Ato" o "Casa"
   * @param {boolean} isAm => para saber si es la tabla AM o PM
   */
  const onChangeInput = (e, flota, horario, rol, minOrMax, atoOCasa, isAm) => {
    const newValue = e.target.value;

    if (isAm) {
      // Modificar en crewStandbyAm
      const newData = { ...crewStandbyAm };
      if (!newData[flota]) newData[flota] = {};
      if (!newData[flota][horario]) newData[flota][horario] = {};
      if (!newData[flota][horario][rol]) newData[flota][horario][rol] = {};
      if (!newData[flota][horario][rol][minOrMax]) {
        newData[flota][horario][rol][minOrMax] = {};
      }
      newData[flota][horario][rol][minOrMax][atoOCasa] = newValue;
      setCrewStandbyAm(newData);

    } else {
      // Modificar en crewStandbyPm
      const newData = { ...crewStandbyPm };
      if (!newData[flota]) newData[flota] = {};
      if (!newData[flota][horario]) newData[flota][horario] = {};
      if (!newData[flota][horario][rol]) newData[flota][horario][rol] = {};
      if (!newData[flota][horario][rol][minOrMax]) {
        newData[flota][horario][rol][minOrMax] = {};
      }
      newData[flota][horario][rol][minOrMax][atoOCasa] = newValue;
      setCrewStandbyPm(newData);
    }
  };

  useEffect(() => {
    return getCrewStandbyBr({
      next: (querySnapshot) => {
        const data = querySnapshot.data();
        if (data && data.dataAm && data.dataPm) {
          setCrewStandbyAm(data.dataAm);
          setCrewStandbyPm(data.dataPm);
          setLastState(data.lastUpdate);
        } else {
          setCrewStandbyAm(CREW_STANDBY_BRA_MAINTEN_AM);
          setCrewStandbyPm(CREW_STANDBY_BRA_MAINTEN_PM);
        }
      },
      error: (e) => {
        console.error(e);
        setCrewStandbyAm(CREW_STANDBY_BRA_MAINTEN_AM);
        setCrewStandbyPm(CREW_STANDBY_BRA_MAINTEN_PM);
      }
    });
  }, [props]);


  const saveChanges = () => {
    const modDate = new Date();
    const newGoals = {
      dataAm: crewStandbyAm,
      dataPm: crewStandbyPm,
    };

    console.log("Datos a guardar:", newGoals);

    updateCrewStandbyBr({
      dataAm: crewStandbyAm,
      dataPm: crewStandbyPm,
      lastUpdate: modDate.toISOString()
    }).then(
      () => {
        setSnackbar({
          msg: "Succesfully saved changes!",
          date: new Date(),
          severity: "success",
        });
        setLastState(modDate.toISOString());
      },
      (error) => {
        console.error(error);
        setSnackbar({
          msg: "Error saving changes. Try again later",
          date: new Date(),
          severity: "error",
        });
      }
    );
  };

  return (
    <div className='main-br'>

      {/*********************************************************************
       * TABLA AM
       * Muestra la data del estado crewStandbyAm (CREW_STANDBY_BRA_MAINTEN_AM)
       *********************************************************************/}
      <table className='table-br'>
        <thead>
          <tr>
            <th className="col-br"></th>
            {CREW_STANDBY_BR_AM_GROUP.map((title, index) => (
              <th colSpan="2" className='scheduler' key={`am-group-${index}`}>
                {title}
              </th>
            ))}
          </tr>
          <tr>
            <th></th>
            {CREW_STANDBY_BR_MANDO_CABINA.map((title, index) => (
              <th className='theader-br borde-br borde-final' key={`am-hora-header-${index}`}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* Fila "leyenda" con colores/íconos (Opcional) */}
          <tr>
            <th></th>
            {CREW_STANDBY_BR_MANDO_CABINA.map((_, index) => (
              <td  className='doble-br' key={`am-legend-${index}`}>
                <td className='simple-br amarillo-br' key={`am-legend-${index}-0`}>
                  <img src={Ato} alt="Ato" style={{ width: "14px", height: "14px" }} />
                </td>
                <td className='simple-br amarillo-br borde-br' key={`am-legend-${index}-1`}>
                  <img src={House} alt="Casa" style={{ width: "14px", height: "14px" }} />
                </td>
                <td className='simple-br rojo-br borde-br' key={`am-legend-${index}-2`}>
                  <img src={Ato} alt="Ato" style={{ width: "14px", height: "14px" }} />
                </td>
                <td className='simple-br rojo-br borde-br' key={`am-legend-${index}-3`}>
                  <img src={House} alt="Casa" style={{ width: "14px", height: "14px" }} />
                </td>
              </td>
            ))}
          </tr>

          {/* Filas: Por cada flota, por cada horario AM, Mando y Cabina */}
          {CREW_STANDBY_BR.map((flota) => (
            <tr key={`am-row-${flota}`}>
              <th className='col1-br'>{flota}</th>

              {CREW_STANDBY_BR_AM_GROUP.map((hora) => {
                // Obtenemos la data "Mando" del estado crewStandbyAm
                const mandoObj = crewStandbyAm[flota]?.[hora]?.["Mando"] || {};
                const minAtoM  = mandoObj?.["Min"]?.["Ato"]  ?? "";
                const minCasaM = mandoObj?.["Min"]?.["Casa"] ?? "";
                const maxAtoM  = mandoObj?.["Max"]?.["Ato"]  ?? "";
                const maxCasaM = mandoObj?.["Max"]?.["Casa"] ?? "";

                // Obtenemos la data "Cabina" del estado crewStandbyAm
                const cabinaObj = crewStandbyAm[flota]?.[hora]?.["Cabina"] || {};
                const minAtoC  = cabinaObj?.["Min"]?.["Ato"]  ?? "";
                const minCasaC = cabinaObj?.["Min"]?.["Casa"] ?? "";
                const maxAtoC  = cabinaObj?.["Max"]?.["Ato"]  ?? "";
                const maxCasaC = cabinaObj?.["Max"]?.["Casa"] ?? "";

                return (
                  <td colSpan="2" className='doble-br' key={`am-${flota}-${hora}`}>
                    {/* MANDO MinAto */}
                    <td className='simple-br' key={`am-${flota}-${hora}-mando-minAto`}>
                      <input
                        type="text"
                        className='input-br'
                        value={minAtoM}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Mando", "Min", "Ato", true)
                        }
                      />
                    </td>
                    {/* MANDO MinCasa */}
                    <td className='simple-br borde-br' key={`am-${flota}-${hora}-mando-minCasa`}>
                      <input
                        type="text"
                        className='input-br'
                        value={minCasaM}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Mando", "Min", "Casa", true)
                        }
                      />
                    </td>
                    {/* MANDO MaxAto */}
                    <td className='simple-br borde-br' key={`am-${flota}-${hora}-mando-maxAto`}>
                      <input
                        type="text"
                        className='input-br'
                        value={maxAtoM}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Mando", "Max", "Ato", true)
                        }
                      />
                    </td>
                    {/* MANDO MaxCasa */}
                    <td className='simple-br borde-br' key={`am-${flota}-${hora}-mando-maxCasa`}>
                      <input
                        type="text"
                        className='input-br'
                        value={maxCasaM}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Mando", "Max", "Casa", true)
                        }
                      />
                    </td>

                    {/* CABINA MinAto */}
                    <td className='simple-br borde-br' key={`am-${flota}-${hora}-cabina-minAto`}>
                      <input
                        type="text"
                        className='input-br'
                        value={minAtoC}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Cabina", "Min", "Ato", true)
                        }
                      />
                    </td>
                    {/* CABINA MinCasa */}
                    <td className='simple-br borde-br' key={`am-${flota}-${hora}-cabina-minCasa`}>
                      <input
                        type="text"
                        className='input-br'
                        value={minCasaC}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Cabina", "Min", "Casa", true)
                        }
                      />
                    </td>
                    {/* CABINA MaxAto */}
                    <td className='simple-br borde-br' key={`am-${flota}-${hora}-cabina-maxAto`}>
                      <input
                        type="text"
                        className='input-br'
                        value={maxAtoC}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Cabina", "Max", "Ato", true)
                        }
                      />
                    </td>
                    {/* CABINA MaxCasa */}
                    <td className='simple-br borde-br' key={`am-${flota}-${hora}-cabina-maxCasa`}>
                      <input
                        type="text"
                        className='input-br'
                        value={maxCasaC}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Cabina", "Max", "Casa", true)
                        }
                      />
                    </td>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>

      <br />

      {/*********************************************************************
       * TABLA PM
       * Muestra la data del estado crewStandbyPm (CREW_STANDBY_BRA_MAINTEN_PM)
       *********************************************************************/}
      <table className='table-br'>
        <thead>
          <tr>
            <th className="col-br"></th>
            {CREW_STANDBY_BR_PM_GROUP.map((title, index) => (
              <th colSpan="2" className='scheduler' key={`pm-group-${index}`}>
                {title}
              </th>
            ))}
          </tr>
          <tr>
            <th></th>
            {CREW_STANDBY_BR_MANDO_CABINA.map((title, index) => (
              <th className='theader-br borde-br borde-final' key={`pm-hora-header-${index}`}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* Leyenda con íconos color (Opcional) */}
          <tr>
            <td></td>
            {CREW_STANDBY_BR_MANDO_CABINA.map((_, index) => (
              <td className='doble-br' key={`pm-legend-${index}`}>
                <td className='simple-br amarillo-br' key={`pm-legend-${index}-0`}>
                  <img src={Ato} alt="Ato" style={{ width: "14px", height: "14px" }} />
                </td>
                <td className='simple-br amarillo-br borde-br' key={`pm-legend-${index}-1`}>
                  <img src={House} alt="Casa" style={{ width: "14px", height: "14px" }} />
                </td>
                <td className='simple-br rojo-br borde-br' key={`pm-legend-${index}-2`}>
                  <img src={Ato} alt="Ato" style={{ width: "14px", height: "14px" }} />
                </td>
                <td className='simple-br rojo-br borde-br' key={`pm-legend-${index}-3`}>
                  <img src={House} alt="Casa" style={{ width: "14px", height: "14px" }} />
                </td>
              </td>
            ))}
          </tr>

          {/* Filas con los inputs PM (Mando & Cabina) por flota */}
          {CREW_STANDBY_BR.map((flota) => (
            <tr key={`pm-row-${flota}`}>
              <th className='col1-br'>{flota}</th>

              {CREW_STANDBY_BR_PM_GROUP.map((hora) => {
                // Data Mando en PM
                const mandoObj = crewStandbyPm[flota]?.[hora]?.["Mando"] || {};
                const minAtoM  = mandoObj?.["Min"]?.["Ato"]  ?? "";
                const minCasaM = mandoObj?.["Min"]?.["Casa"] ?? "";
                const maxAtoM  = mandoObj?.["Max"]?.["Ato"]  ?? "";
                const maxCasaM = mandoObj?.["Max"]?.["Casa"] ?? "";

                // Data Cabina en PM
                const cabinaObj = crewStandbyPm[flota]?.[hora]?.["Cabina"] || {};
                const minAtoC  = cabinaObj?.["Min"]?.["Ato"]  ?? "";
                const minCasaC = cabinaObj?.["Min"]?.["Casa"] ?? "";
                const maxAtoC  = cabinaObj?.["Max"]?.["Ato"]  ?? "";
                const maxCasaC = cabinaObj?.["Max"]?.["Casa"] ?? "";

                return (
                  <td colSpan="2" className='doble-br' key={`pm-${flota}-${hora}`}>
                    {/* Mando MinAto */}
                    <td className='simple-br' key={`pm-${flota}-${hora}-mando-minAto`}>
                      <input
                        type="text"
                        className='input-br'
                        value={minAtoM}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Mando", "Min", "Ato", false)
                        }
                      />
                    </td>
                    {/* Mando MinCasa */}
                    <td className='simple-br borde-br' key={`pm-${flota}-${hora}-mando-minCasa`}>
                      <input
                        type="text"
                        className='input-br'
                        value={minCasaM}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Mando", "Min", "Casa", false)
                        }
                      />
                    </td>
                    {/* Mando MaxAto */}
                    <td className='simple-br borde-br' key={`pm-${flota}-${hora}-mando-maxAto`}>
                      <input
                        type="text"
                        className='input-br'
                        value={maxAtoM}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Mando", "Max", "Ato", false)
                        }
                      />
                    </td>
                    {/* Mando MaxCasa */}
                    <td className='simple-br borde-br' key={`pm-${flota}-${hora}-mando-maxCasa`}>
                      <input
                        type="text"
                        className='input-br'
                        value={maxCasaM}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Mando", "Max", "Casa", false)
                        }
                      />
                    </td>

                    {/* Cabina MinAto */}
                    <td className='simple-br borde-br' key={`pm-${flota}-${hora}-cabina-minAto`}>
                      <input
                        type="text"
                        className='input-br'
                        value={minAtoC}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Cabina", "Min", "Ato", false)
                        }
                      />
                    </td>
                    {/* Cabina MinCasa */}
                    <td className='simple-br borde-br' key={`pm-${flota}-${hora}-cabina-minCasa`}>
                      <input
                        type="text"
                        className='input-br'
                        value={minCasaC}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Cabina", "Min", "Casa", false)
                        }
                      />
                    </td>
                    {/* Cabina MaxAto */}
                    <td className='simple-br borde-br' key={`pm-${flota}-${hora}-cabina-maxAto`}>
                      <input
                        type="text"
                        className='input-br'
                        value={maxAtoC}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Cabina", "Max", "Ato", false)
                        }
                      />
                    </td>
                    {/* Cabina MaxCasa */}
                    <td className='simple-br borde-br' key={`pm-${flota}-${hora}-cabina-maxCasa`}>
                      <input
                        type="text"
                        className='input-br'
                        value={maxCasaC}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Cabina", "Max", "Casa", false)
                        }
                      />
                    </td>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="cf-buttons">
        <div className="last-mod">
          Last modification made:{" "}
          {lastState ? (
            <Moment format="DD/MM/yyyy HH:mm" tz="UTC">
              {lastState}
            </Moment>
          ) : null}
        </div>
        <Button
          variant="contained"
          classes={{ root: 'vw-button' }}
          onClick={saveChanges}
          disableElevation
        >
          Apply changes to video wall
        </Button>
      </div>

      {snackbar ? (
        <GenericSnackbar
          key={snackbar.date}
          severity={snackbar.severity}
          message={snackbar.msg}
        />
      ) : null}
    </div>
  );
}

export default CrewStandbyMaintainerBrasil;
