import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_DOMAIN}`,
  projectId: `${process.env.REACT_APP_PROJECT_ID}`,
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const localPersistence = firebase.auth.Auth.Persistence.LOCAL;
export const googleAuthProvider =
  new firebase.auth.GoogleAuthProvider().setCustomParameters({
    hd: "latam.com",
  });

export const streamContingencyFlights = (observer) => {
  try {
    return firestore
      .collection("videowall-contingencyFlights")
      .doc("latest")
      .onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const streamCriticalFlights = (observer) => {
  try {
    return firestore
      .collection("videowall-criticalFlights")
      .doc("latest")
      .onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};
export const streamSummaryLimmops = (observer) => {
  try {
    return firestore
      .collection("videowall-SummaryLimops")
      .doc("operationalLimitations")
      .onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const streamParSubFlota = (observer) => {
  try {
    return firestore
      .collection("videowall-SummaryLimops")
      .doc("parSubflota")
      .onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const streamfleetDictionary = (observer) => {
  try {
    return firestore
      .collection("videowall-SummaryLimops")
      .doc("fleetDictionary")
      .onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const streamCriticalAircrafts = async (observer) => {
  try {
    return firestore
      .collection("videowall-criticalAircrafts")
      .doc("latest")
      .onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const streamCrewStandby = async (observer) => {
  try {
    const fltSummaryShiftRef = firestore
      .collection("videowall-CrewStandby")
      .doc("fltSummaryShift");
    const fltSummaryShiftBraRef = firestore
      .collection("videowall-CrewStandby")
      .doc("fltSummaryShiftBra");

    return firestore.runTransaction(async (transaction) => {
      const fltSummaryShiftDoc = await transaction.get(fltSummaryShiftRef);
      const fltSummaryShiftBraDoc = await transaction.get(
        fltSummaryShiftBraRef
      );

      observer.next({
        fltSummaryShift: fltSummaryShiftDoc.data(),
        fltSummaryShiftBra: fltSummaryShiftBraDoc.data(),
      });
    });
  } catch (error) {
    console.error(error);
  }
};

export const streamCriticalAircraftsDictionaryFlotaSubFlota = async (
  observer
) => {
  try {
    return firestore
      .collection("videowall-criticalAircrafts")
      .doc("diccionarioFlotaSubFlota")
      .onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};


export const streamUserConfiguration = (email, observer) => {
  try {
    return firestore
      .collection("videowall-configuration")
      .doc(email)
      .onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const streamTimezone = (observer) => {
  try {
    return firestore
      .collection("videowall-timezone")
      .doc("latest")
      .onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const streamWeather = (observer) => {
  try {
    return firestore
      .collection("videowall-weather")
      .doc("latest")
      .onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const streamOTP = (observer) => {
  try {
    return firestore.collection("videowall-otp").onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const streamGroundSupport = (observer) => {
  try {
    return firestore
      .collection("videowall-groundSupport")
      .doc("SSC")
      .onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const streamHeader = (observer) => {
  try {
    return firestore.collection("videowall-headerData").onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const streamNews = (cco, observer) => {
  try {
    return firestore
      .collection("videowall-headerData")
      .doc("news" + cco.toUpperCase())
      .onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const streamUsers = (observer) => {
  try {
    return firestore.collection("videowall-users").onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const getPhoneData = async () => {
  try {
    const contacts = await firestore
      .collection("videowall-phoneDialer")
      .doc("contacts")
      .get();
    const extensions = await firestore
      .collection("videowall-phoneDialer")
      .doc("extensions")
      .get();
    return {
      contacts: contacts.data() ? contacts.data().data : [],
      extensions: extensions.data() ? extensions.data().data : [],
    };
  } catch (error) {
    console.error(error);
  }
};

export const getDefaultConfiguration = async (cco) => {
  try {
    const config = await firestore
      .collection("videowall-configuration")
      .doc(`videowalls.${cco.toLowerCase()}@latam.com`)
      .get();
    if (config.data()) {
      return config.data();
    } else {
      return {};
    }
  } catch (error) {
    console.error(error);
  }
};

export const getUserConfiguration = async (email, cco) => {
  try {
    const config = await firestore
      .collection("videowall-configuration")
      .doc(email.toLowerCase())
      .get();
    if (config.data()) {
      return config.data();
    } else {
      const defaultConf = await getDefaultConfiguration(cco);
      return defaultConf;
    }
  } catch (error) {
    console.error(error);
  }
};
export const getSummaryLimopsGoals = (observer) => {
  try {
    return firestore
      .collection("videowall-configuration")
      .doc("gols")
      .onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const updateSummaryLimopsGoals = async (goals) => {
  try {
    await firestore
      .collection("videowall-configuration")
      .doc("gols")
      .set(goals, { merge: true });
    console.log("Goals updated successfully!");
  } catch (error) {
    console.error("Error updating goals:", error);
  }
};

export const getCrewStandbySsc = (observer) => {
  try {
    return firestore
      .collection("videowall-configuration")
      .doc("crewStdby-group-Ssc")
      .onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const updateCrewStandbyScc = async (goals) => {
  try {
    await firestore
      .collection("videowall-configuration")
      .doc("crewStdby-group-Ssc")
      .set(goals);
    console.log("crewStdby-group-Ssc updated successfully!");

  } catch (error) {
    console.error("Error updating crewStdby-group-Ssc:", error);
  }
};

export const getCrewStandbyBr = (observer) => {
  try {
    return firestore
      .collection("videowall-configuration")
      .doc("crewStdby-group-Bra")
      .onSnapshot(observer);
  } catch (error) {
    console.error(error);
  }
};

export const updateCrewStandbyBr = async (goals) => {
  try {
    await firestore
      .collection("videowall-configuration")
      .doc("crewStdby-group-Bra")
      .set(goals, { merge: false });
    console.log("crewStdby-group-Br updated successfully!");
  } catch (error) {
    console.error("Error updating crewStdby-group-Br:", error);
  }
};

export const streamNps = (observer) => {
  try {
    return firestore.doc("videowall-NpsContingency/nps").onSnapshot(
      (snapshot) => {
        const npsData = snapshot.data();
        const lastUpdate = npsData.lastUpdate
        if (npsData && npsData.data && npsData.data.length > 0) {
          const { npsL7D, npsFy } = npsData.data[0];
   
          observer.next({ lastUpdate, npsL7D, npsFy });
        } else {
          observer.next({
            lastUpdate: lastUpdate,
            npsL7D: "Error al obtener datos",
            npsFy: "Error al obtener datos",
          });
        }
      },
      (error) => {
        console.error("Error fetching data from Firestore:", error);
        observer.error(error);
      }
    );
  } catch (error) {
    console.error("Error in streamNps function:", error);
    observer.error(error);
  }
};
