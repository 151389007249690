import React from "react";
import "./CrewStandbyGroupSSC.css";

function CrewStandbyGroupSSC({ ctalFlightGrp, crewStdbyGroupSscData }) {
  
  const groupedData = ctalFlightGrp.reduce((acc, item) => {
    const key = `${item.subFleetCode}${item.baseCode}${item.companyCode}`;
    if (!acc[key]) {
      acc[key] = { ...item, ranges: {} };
    }
    acc[key].ranges[item.timeRange] = {
      MANDO: item.MANDO,
      CABINA: item.CABINA,
    };
    return acc;
  }, {});
 
  function getDynamicColor({
      value,
      subFleetCode,
      dayPart,
      crewType,
      isT24

    }) {
      if (isT24) {
        value = getT24(value, subFleetCode);
      }
      if (
        !crewStdbyGroupSscData ||
        !crewStdbyGroupSscData[subFleetCode] ||
        !crewStdbyGroupSscData[subFleetCode][dayPart] ||
        !crewStdbyGroupSscData[subFleetCode][dayPart][crewType]
      ) {
        console.warn(
          `No se encontró la configuración para subFleetCode: "${subFleetCode}", dayPart: "${dayPart}", crewType: "${crewType}"`
        );
        return "summary-limops-col black";
      }

      const { min, max } = crewStdbyGroupSscData[subFleetCode][dayPart][crewType];
      const valMin = Number(Array.isArray(min) ? min[0] : min);
      const valMax = Number(Array.isArray(max) ? max[0] : max);
      
      if ( value === '-') {
        return "summary-limops-col black";
      }
    
      if (isNaN(valMin) || isNaN(valMax)) {
       return "summary-limops-col red";
      }
      
      const numericValue = Number(value) || 0;
      
      if (numericValue <= valMax)  return "summary-limops-col red";
      if (numericValue <= valMin) return "summary-limops-col yellow";
      if (numericValue > valMin)  return "summary-limops-col white"

      return "summary-limops-col black";
    }

    function getAtoMandoCabinaColor({ value, subFleetCode, dayPart, crewType }) {
      if (!crewStdbyGroupSscData ||
          !crewStdbyGroupSscData[subFleetCode] ||
          !crewStdbyGroupSscData[subFleetCode][dayPart] ||
          !crewStdbyGroupSscData[subFleetCode][dayPart][crewType]) {
        console.warn(
          `No se encontró la configuración para subFleetCode: "${subFleetCode}", dayPart: "${dayPart}", crewType: "${crewType}"`
        );
        return "summary-limops-col black";
      }
      
      let { min, max } = crewStdbyGroupSscData[subFleetCode][dayPart][crewType];
      
      min = Array.isArray(min) ? min : [min];
      max = Array.isArray(max) ? max : [max];
      
      if (value === '-') {
        return "summary-limops-col black";
      }
      
      const parts = String(value)
        .split("/")
        .map(part => (isNaN(part) ? "-" : parseInt(part, 10)));
      
      let mostCriticalColor = "black"; // Color menos crítico por defecto
    
      for (let i = 0; i < parts.length; i++) {
        if (parts[i] === "-") continue;
        
        const numericValue = parts[i];
        const valMin = min[i] !== "-" ? Number(min[i]) : NaN;
        const valMax = max[i] !== "-" ? Number(max[i]) : NaN;
        
        if (isNaN(valMin) || isNaN(valMax)) {
          continue;
        }
        
        let color = "black";
        if (numericValue <= valMax) {
          color = "red";
        } else if (numericValue <= valMin) {
          color = "yellow";
        } else if (numericValue > valMin) {
          color = "white";
        }
        
        // Determinar el color más crítico
        if (color === "red" || (color === "yellow" && mostCriticalColor !== "red") ||
            (color === "white" && mostCriticalColor === "black")) {
          mostCriticalColor = color;
        }
      }
      
      return `summary-limops-col ${mostCriticalColor}`;
    }
    
    
    
    
  return (
    <div className={"mySlides3 cf-fade crew"}>
      {Object.values(groupedData).map((ct, index) => {
        const combinedCode = `${ct.subFleetCode}${ct.companyCode?.substring(0, 2) || ""
        }`;
          return (
            <div
              className="summary-limops-tittle summary-limops-row-style"
              key={ct.idx}
            >
              <div className="summary-limops-colx2 ssc">{combinedCode}</div>
              {/* AM MANDO*/}
              <div
            className={getDynamicColor({
              value: ct.mandoAm,
              subFleetCode: combinedCode, 
              dayPart: "AM",
              crewType: "Mando",
              isT24: false
            })}
          >
            {ct.mandoAm || 0}
          </div>
            {/* AM CABINA*/}
          <div
            className={getDynamicColor({
              value: ct.cabinaAm,
              subFleetCode: combinedCode, 
              dayPart: "AM",
              crewType: "Cabina",
              isT24: false
            })} 
          >
            {ct.cabinaAm || 0}
          </div>
           {/* PM MANDO*/}
              <div
                 className={getDynamicColor({
                  value: ct.mandoPm,
                  subFleetCode: combinedCode, 
                  dayPart: "PM",
                  crewType: "Mando",
                  isT24: false
                })} 
              >
                {ct.mandoPm || 0}
              </div>
               {/* PM CABINA*/}
              <div
                 className={getDynamicColor({
                  value: ct.cabinaPm,
                  subFleetCode: combinedCode, 
                  dayPart: "PM",
                  crewType: "Cabina",
                  isT24: false
                })} 
              >
                {ct.cabinaPm || 0}
              </div>
              {/* ATO MANDO*/}
              <div 
                className={getAtoMandoCabinaColor({
                  value: getATO(ct.cabinCrewDistribution, combinedCode),
                  subFleetCode: combinedCode,
                  dayPart: "ATO",
                  crewType: "Mando",
                })}
                >
                {getATO(ct.cabinCrewDistribution, combinedCode)}
              </div>
              {/* ATO CABINA*/}
              <div 
                className={getAtoMandoCabinaColor({
                  value: getATO(ct.crewDistribution, combinedCode),
                  subFleetCode: combinedCode,
                  dayPart: "ATO",
                  crewType: "Cabina",
                })}
                >
                {getATO(ct.crewDistribution,combinedCode)}
              </div>
              {/* T24 MANDO*/}
              <div 
                className={getDynamicColor({
                value: ct.mandoT24,
                subFleetCode: combinedCode,
                dayPart: "T24",
                crewType: "Mando",
                isT24: true
              })}
              >
                {getT24(ct.mandoT24,combinedCode)}
              </div>
              {/* T24 CABINA*/}
              <div 
              className={getDynamicColor({
                value: ct.cabinaT24,
                subFleetCode: combinedCode,
                dayPart: "T24",
                crewType: "Cabina",
                isT24: true
              })}
              >
                {getT24(ct.cabinaT24,combinedCode)}
              </div>
            </div>
          );
        
      })}
    </div>
  );
}
function getT24(numero, key) {
  if (key.includes('PE')) {
      return numero || 0;
  } else {
    return '-';

  }
}
function getATO(numero, key) {
  let withoutAto  = ["777BR","787BR","320EC"];
  if (withoutAto.includes(key)) {
    return '-';
  } else {
      return numero || 0;
  }
}

export default CrewStandbyGroupSSC;
