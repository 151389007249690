import React from "react";
import "./CrewStandbyGroupBR.css";

function getColorClass(valueString, flota, timeRange, role, crewStdbyGroupBraData) {

  const isLargeString = valueString.length >= 4;
  const adjustedRange = timeRange.replace("-", " - ");
  const AM_RANGES = ["00:00-03:00", "03:00-06:00", "06:00-09:00", "09:00-12:00"];
  const PM_RANGES = ["12:00-15:00", "15:00-18:00", "18:00-21:00", "21:00-00:00"];

  let dataObj;
  if (AM_RANGES.includes(timeRange)) {
    dataObj = crewStdbyGroupBraData?.dataAm;
  } else if (PM_RANGES.includes(timeRange)) {
    dataObj = crewStdbyGroupBraData?.dataPm;
  } else {
    return isLargeString ? "black-small" : "black";
  }

  if (valueString === "-/-") {
    return isLargeString ? "black-small" : "black";
  }
  
  const [atoStr, casaStr] = valueString.split("/");
  const atoVal = parseInt(atoStr, 10) || 0;
  const casaVal = parseInt(casaStr, 10) || 0;

  const minAto = dataObj?.[flota]?.[adjustedRange]?.[role]?.Min?.Ato ?? 0; //
  const minCasa = dataObj?.[flota]?.[adjustedRange]?.[role]?.Min?.Casa ?? 0;
  const maxAto = dataObj?.[flota]?.[adjustedRange]?.[role]?.Max?.Ato ?? 0;
  const maxCasa = dataObj?.[flota]?.[adjustedRange]?.[role]?.Max?.Casa ?? 0;
 

  if (atoVal <= maxAto || casaVal <= maxCasa) return isLargeString ? "red-small" : "red";
  if (atoVal <= minAto || casaVal <= minCasa) return isLargeString ? "yellow-small" : "yellow";
  if (atoVal > minAto || casaVal > minCasa) return isLargeString ? "white-small" : "white";
  
  return isLargeString ? "black-small" : "black";
}

function CrewStandbyGroupBR({ ctalFlightGrp, crewStdbyGroupBraData }) {
  const allTimeRanges = [
    "00:00-03:00",
    "03:00-06:00",
    "06:00-09:00",
    "09:00-12:00",
    "12:00-15:00",
    "15:00-18:00",
    "18:00-21:00",
    "21:00-00:00",
  ];

  const rows = ctalFlightGrp.map((group) =>
    group.reduce((acc, item) => {
      const key = `${item.subFleetCode}${item.baseCode}`;
      if (!acc[key]) {
        acc[key] = { key, ranges: {} };
      }
      acc[key].ranges[item.timeRange] = {
        MANDO: item.MANDO,
        CABINA: item.CABINA,
      };
      return acc;
    }, {})
  );

  const getValueOrZeroBrasil = (value) => {
    if (typeof value === "string" && value.includes("/")) {
      const [effective, remaining] = value.split("/");
      return `${effective === "-" ? "0" : effective}/${
        remaining === "-" ? "0" : remaining
      }`;
    } else if (typeof value === "number") {
      return value === 0 ? "0/0" : `${value}/0`;
    } else if (value === undefined || value === null || value === "") {
      return "-/-";
    } else {
      return `${value}/0`;
    }
  };

  return (
    <div className="mySlides3 cf-fade crew">
      {rows.map((row, rowIndex) => (
        <React.Fragment key={rowIndex}>
          {Object.values(row).map((ct, ctIndex) => (
            <div
              className="summary-limops-tittle summary-limops-row-style"
              key={`${rowIndex}-${ctIndex}`}
            >
              <div className="summary-limops-colx2 isBrasil">{ct.key}</div>

              {allTimeRanges.map((range, rangeIdx) => {
                const rangeData = ct.ranges[range] || {};
                const mando = getValueOrZeroBrasil(rangeData.MANDO);
                const cabina = getValueOrZeroBrasil(rangeData.CABINA);

                const mandoClass = getColorClass(
                  mando, //valor
                  ct.key, // flota
                  range,  // rango "00:00-03:00"
                  "Mando",
                  crewStdbyGroupBraData
                );
                const cabinaClass = getColorClass(
                  cabina,
                  ct.key,
                  range,
                  "Cabina",
                  crewStdbyGroupBraData
                );

                return (
                  <React.Fragment key={`${rowIndex}-${ctIndex}-${rangeIdx}`}>
                    <div className={`summary-limops-col ${mandoClass}`}>
                      {mando}
                    </div>
                    <div className={`summary-limops-col ${cabinaClass}`}>
                      {cabina}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
}

export default CrewStandbyGroupBR;
