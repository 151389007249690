import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from 'react';
import './CrewStandbyMaintainer.css';
import Moment from "react-moment";
import {getCrewStandbySsc, updateCrewStandbyScc} from "../../../../firebase";
import GenericSnackbar from "../../../GenericSnackbar/GenericSnackbar";
import {CREW_STANDBY_SSC_MAINTEN, SUMMARY_CREW_STANDBY_SSC_MAINTEN} from '../../../../utils/Constants';


function CrewStandbyMaintainer(props) {
  
  const [snackbar, setSnackbar] = useState("");
  const [crewStandbyGroupSsr, setCrewStandbyGroupSsr] = useState(CREW_STANDBY_SSC_MAINTEN);
  const [lastState, setLastState] = useState();

  const TURNS = ["AM", "PM", "ATO", "T24"];

  useEffect(() => {
    return getCrewStandbySsc({
      next: (querySnapshot) => {
        if (querySnapshot.data()) {
          setCrewStandbyGroupSsr(querySnapshot.data().data);
          setLastState(querySnapshot.data().lastUpdate);
        }
      },
      error: (e) => {
        console.error(e);
      }
    });
  }, [props]);


const handleChangeSimple = (e, subflota, turn, crew, field) => {
  const newValue = e.target.value;
  const newData = { ...crewStandbyGroupSsr };

  if (!newData[subflota]) newData[subflota] = {};
  if (!newData[subflota][turn]) newData[subflota][turn] = {};
  if (!newData[subflota][turn][crew]) newData[subflota][turn][crew] = {};

  newData[subflota][turn][crew][field] = newValue;
  setCrewStandbyGroupSsr(newData);
};

  const handleChangeArray = (e, subflota, turn, crew, field, idx) => {
  const newValue = e.target.value;
  const newData = { ...crewStandbyGroupSsr };

  if (!newData[subflota]) newData[subflota] = {};
  if (!newData[subflota][turn]) newData[subflota][turn] = {};
  if (!newData[subflota][turn][crew]) newData[subflota][turn][crew] = {};

  if (!Array.isArray(newData[subflota][turn][crew][field])) {
    newData[subflota][turn][crew][field] = ["", "", "", ""];
  }
  newData[subflota][turn][crew][field][idx] = newValue;
  setCrewStandbyGroupSsr(newData);
};

  const saveChanges = () => {
  const newGoals = { ...crewStandbyGroupSsr };
  const modDate = new Date();

  if (!newGoals || Object.keys(newGoals).length === 0) {
    console.error("Error: No hay datos válidos para guardar");
    return;
  }

  updateCrewStandbyScc({
    data: newGoals,
    lastUpdate: modDate.toISOString(),
  }).then(
    () => {
      setSnackbar({
        msg: "Succesfully saved changes!",
        date: new Date(),
        severity: "success",
      });
    },
    (error) => {
      console.error(error);
      setSnackbar({
        msg: "Error saving changes. Try again later",
        date: new Date(),
        severity: "error",
      });
    }
  );
};

    return (
      <div>
        <table className="table-ssc">
          <thead>
            {/* 1) Fila de turnos */}
            <tr className="row-schedule">
            <th rowSpan={3}></th>
              <th className="row-schedule-th" colSpan={28}>
              <div className="row-schedule-container">
                <div className="row-schedule-box am-ssc">AM</div>
                <div className="row-schedule-box pm-ssc">PM</div>
                <div className="row-schedule-box ato-ssc">ATO</div>
                <div className="row-schedule-box t24-ssc">T24</div>
              </div>
              </th>
            </tr>
  
            {/* 2) Fila "Mando" / "Cabina" */}
            <tr >
              {TURNS.map((turn) => {
                if (turn === "ATO") {
                  return (
                    <React.Fragment key={`${turn}-second-row`}>
                      <th colSpan={8}>
                        <div className="mando-box-ato">Mando</div>
                      </th>
                      <th colSpan={8}>
                        <div className="cabina-box-ato">Cabina</div>
                      </th>
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={`${turn}-second-row`}>
                      <th colSpan={2}>
                        <div className={`mando-box-${turn.toLowerCase()}`}>Mando</div>
                      </th>
                      <th colSpan={2}>
                        <div className={`cabina-box-${turn.toLowerCase()}`}>Cabina</div>
                      </th>
                    </React.Fragment>
                  );
                }
              })}
            </tr>
          {/* 3) Fila coloreada (Min = amarillo, Max = rojo) */}
          <tr>
          <th colSpan={28}>
          <div className="color-row-container">
            {TURNS.map((turn) => {
              if (turn === "ATO") {
                const cells = [];
                for (let i = 0; i < 4; i++) {
                  cells.push(
                    <th
                      key={`${turn}-Mando-min-${i}`}
                      className="color-box-ato-a-mando amarillo-ssc "
                    ></th>
                  );
                }
                // Mando max (4) => rojo
                for (let i = 0; i < 4; i++) {
                  cells.push(
                    <th
                      key={`${turn}-Mando-max-${i}`}
                      className="color-box-ato-r-mando rojo-ssc"
                    ></th>
                  );
                }

                // Cabina min (4) => amarillo
                for (let i = 0; i < 4; i++) {
                  cells.push(
                    <th
                      key={`${turn}-Cabina-min-${i}`}
                      className="color-box-ato-a-cabina amarillo-ssc"
                    ></th>
                  );
                }
                // Cabina max (4) => rojo
                for (let i = 0; i < 4; i++) {
                  cells.push(
                    <th
                      key={`${turn}-Cabina-max-${i}`}
                      className="color-box-ato-r-cabina rojo-ssc "
                    ></th>
                  );
                }
                return (
                  <React.Fragment key={`${turn}-third-row`}>
                    <div className="color-th-container-ato">{cells}</div>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={`${turn}-third-row`}>
                  <div className={`color-th-container-mando-${turn.toLowerCase()}`}>
                    <th
                      className={`color-box-a-mando-${turn.toLowerCase()} amarillo-ssc`}
                    ></th>
                    <th
                      className={`color-box-r-mando-${turn.toLowerCase()} rojo-ssc`}
                    ></th>
                  </div>
                  <div className={`color-th-container-cabina-${turn.toLowerCase()}`}>
                    <th
                      className={`color-box-a-cabina-${turn.toLowerCase()} amarillo-ssc`}
                    ></th>
                    <th
                      className={`color-box-r-cabina-${turn.toLowerCase()} rojo-ssc`}
                    ></th>
                  </div>
                </React.Fragment>
                );
              }
            })}
               </div>
               </th>
          </tr>
          </thead>
  
          <tbody className="body-inputs">
            {/* Filas de datos: cada subflota */}
            {SUMMARY_CREW_STANDBY_SSC_MAINTEN.map((subflota) => (
              <tr key={subflota} className="row-inputs">
                <th className="col1">{subflota}</th>
                {TURNS.map((turn) => {
                  if (turn === "ATO") {
                    const celdas = [];
                      const mandoMinArr =
                      crewStandbyGroupSsr[subflota]?.[turn]?.["Mando"]?.min || [];
                    for (let i = 0; i < 4; i++) {
                      const val = mandoMinArr[i] ?? "";
                      
                      const tdClass = 
                      i === 0 ? "doble-ssc-ato-mando-a-0" :
                      i === 1 ? "doble-ssc-ato-mando-a-1" :
                      i === 2 ? "doble-ssc-ato-mando-a-2" :
                      "doble-ssc-ato-mando-a-3";
                  
                      const inputClass =
                        i === 0 ? "none-ssc-ato-mando-a-0" :
                        i === 1 ? "none-ssc-ato-mando-a-1" :
                        i === 2 ? "none-ssc-ato-mando-a-2" :
                        "none-ssc-ato-mando-a-3";

                      celdas.push(
                        <div className="container-inputs-ato-mando-a">
                          <td
                            className={tdClass}
                            key={`${subflota}-${turn}-Mando-min-${i}`}
                          >
                            <input
                              type="text"
                              className={inputClass}
                              value={val}
                              onChange={(e) =>
                                handleChangeArray(e, subflota, turn, "Mando", "min", i)
                              }
                            />
                          </td>
                        </div>
                      );
                    }
                    const mandoMaxArr =
                      crewStandbyGroupSsr[subflota]?.[turn]?.["Mando"]?.max || [];
                    for (let i = 0; i < 4; i++) {
                      const val = mandoMaxArr[i] ?? "";

                      const tdClass = 
                      i === 0 ? "doble-ssc-ato-mando-r-0" :
                      i === 1 ? "doble-ssc-ato-mando-r-1" :
                      i === 2 ? "doble-ssc-ato-mando-r-2" :
                      "doble-ssc-ato-mando-r-3";
                  
                      const inputClass =
                        i === 0 ? "none-ssc-ato-mando-r-0" :
                        i === 1 ? "none-ssc-ato-mando-r-1" :
                        i === 2 ? "none-ssc-ato-mando-r-2" :
                        "none-ssc-ato-mando-r-3";
                      celdas.push(
                        <div className="container-inputs-ato-mando-r">
                        <td
                          className={tdClass}
                          key={`${subflota}-${turn}-Mando-max-${i}`}
                        >
                          <input
                            type="text"
                            className={inputClass}
                            value={val}
                            onChange={(e) =>
                              handleChangeArray(e, subflota, turn, "Mando", "max", i)
                            }
                          />
                        </td>
                        </div>
                      );
                    }
  
                    // Cabina min
                    const cabinaMinArr =
                      crewStandbyGroupSsr[subflota]?.[turn]?.["Cabina"]?.min || [];
                    for (let i = 0; i < 4; i++) {
                      const val = cabinaMinArr[i] ?? "";

                      const tdClass = 
                      i === 0 ? "doble-ssc-ato-cabina-a-0" :
                      i === 1 ? "doble-ssc-ato-cabina-a-1" :
                      i === 2 ? "doble-ssc-ato-cabina-a-2" :
                      "doble-ssc-ato-cabina-a-3";
                  
                      const inputClass =
                        i === 0 ? "none-ssc-ato-cabina-a-0" :
                        i === 1 ? "none-ssc-ato-cabina-a-1" :
                        i === 2 ? "none-ssc-ato-cabina-a-2" :
                        "none-ssc-ato-cabina-a-3";

                      celdas.push(
                        <div className="container-inputs-ato-cabina-a">
                        <td
                          className={tdClass}
                          key={`${subflota}-${turn}-Cabina-min-${i}`}
                        >
                          <input
                            type="text"
                            className={inputClass}
                            value={val}
                            onChange={(e) =>
                              handleChangeArray(e, subflota, turn, "Cabina", "min", i)
                            }
                          />
                        </td>
                        </div>
                      );
                    }
  
                    // Cabina max
                    const cabinaMaxArr =
                      crewStandbyGroupSsr[subflota]?.[turn]?.["Cabina"]?.max || [];
                    for (let i = 0; i < 4; i++) {
                      const val = cabinaMaxArr[i] ?? "";

                      const tdClass = 
                      i === 0 ? "doble-ssc-ato-cabina-r-0" :
                      i === 1 ? "doble-ssc-ato-cabina-r-1" :
                      i === 2 ? "doble-ssc-ato-cabina-r-2" :
                      "doble-ssc-ato-cabina-r-3";
                  
                      const inputClass =
                        i === 0 ? "none-ssc-ato-cabina-r-0" :
                        i === 1 ? "none-ssc-ato-cabina-r-1" :
                        i === 2 ? "none-ssc-ato-cabina-r-2" :
                        "none-ssc-ato-cabina-r-3";

                      celdas.push(
                        <div className="container-inputs-ato-cabina-r">
                        <td
                          className={tdClass}
                          key={`${subflota}-${turn}-Cabina-max-${i}`}
                        >
                          <input
                            type="text"
                            className={inputClass}
                            value={val}
                            onChange={(e) =>
                              handleChangeArray(e, subflota, turn, "Cabina", "max", i)
                            }
                          />
                        </td>
                        </div>
                      );
                    }
  
                    return celdas;
                  } else {
                    // AM, PM, T24 => 4 columnas: Mando min, Mando max, Cabina min, Cabina max
                    const mandoMin =
                      crewStandbyGroupSsr[subflota]?.[turn]?.["Mando"]?.min ?? "";
                    const mandoMax =
                      crewStandbyGroupSsr[subflota]?.[turn]?.["Mando"]?.max ?? "";
                    const cabinaMin =
                      crewStandbyGroupSsr[subflota]?.[turn]?.["Cabina"]?.min ?? "";
                    const cabinaMax =
                      crewStandbyGroupSsr[subflota]?.[turn]?.["Cabina"]?.max ?? "";
  
                  if (turn === "T24") {
                  return (
                    <React.Fragment key={`${subflota}-${turn}`}>
                      <div className="container-inputs-mando-t24">
                        <td className="doble-ssc-mando-a-t24">
                          <input
                            type="text"
                            className="none-ssc-mando-a-t24"
                            value={mandoMin}
                            onChange={(e) =>
                              handleChangeSimple(e, subflota, turn, "Mando", "min")
                            }
                          />
                        </td>
                        <td className="doble-ssc-mando-r-t24">
                          <input
                            type="text"
                            className="none-ssc-mando-r-t24"
                            value={mandoMax}
                            onChange={(e) =>
                              handleChangeSimple(e, subflota, turn, "Mando", "max")
                            }
                          />
                        </td>
                      </div>
                      <div className="container-inputs-cabina-t24">
                        <td className="doble-ssc-cabina-a-t24">
                          <input
                            type="text"
                            className="none-ssc-cabina-a-t24"
                            value={cabinaMin}
                            onChange={(e) =>
                              handleChangeSimple(e, subflota, turn, "Cabina", "min")
                            }
                          />
                        </td>
                        <td className="doble-ssc-cabina-r-t24">
                          <input
                            type="text"
                            className="none-ssc-cabina-r-t24"
                            value={cabinaMax}
                            onChange={(e) =>
                              handleChangeSimple(e, subflota, turn, "Cabina", "max")
                            }
                          />
                        </td>
                      </div>
                    </React.Fragment>
                  );
                } else {
                  if (turn === "PM") {
                    return (
                    <React.Fragment key={`${subflota}-${turn}`}>
                      <div className="container-inputs-mando-pm">
                        <td className="doble-ssc-mando-a-pm">
                          <input
                            type="text"
                            className="none-ssc-mando-a-pm"
                            value={mandoMin}
                            onChange={(e) =>
                              handleChangeSimple(e, subflota, turn, "Mando", "min")
                            }
                          />
                        </td>
                        <td className="doble-ssc-mando-r-pm">
                          <input
                            type="text"
                            className="none-ssc-mando-r-pm"
                            value={mandoMax}
                            onChange={(e) =>
                              handleChangeSimple(e, subflota, turn, "Mando", "max")
                            }
                          />
                        </td>
                      </div>
                      <div className="container-inputs-cabina-pm">
                        <td className="doble-ssc-cabina-a-pm">
                          <input
                            type="text"
                            className="none-ssc-cabina-a-pm"
                            value={cabinaMin}
                            onChange={(e) =>
                              handleChangeSimple(e, subflota, turn, "Cabina", "min")
                            }
                          />
                        </td>
                        <td className="doble-ssc-cabina-r-pm">
                          <input
                            type="text"
                            className="none-ssc-cabina-r-pm"
                            value={cabinaMax}
                            onChange={(e) =>
                              handleChangeSimple(e, subflota, turn, "Cabina", "max")
                            }
                          />
                        </td>
                      </div>
                    </React.Fragment>
                  );
                }else{
                  return (//AM
                    <React.Fragment key={`${subflota}-${turn}`}>
                      <div className="container-inputs-mando">
                        <td className="doble-ssc-mando-a">
                          <input
                            type="text"
                            className="none-ssc-mando-a"
                            value={mandoMin}
                            onChange={(e) =>
                              handleChangeSimple(e, subflota, turn, "Mando", "min")
                            }
                          />
                        </td>
                        <td className="doble-ssc-mando-r">
                          <input
                            type="text"
                            className="none-ssc-mando-r"
                            value={mandoMax}
                            onChange={(e) =>
                              handleChangeSimple(e, subflota, turn, "Mando", "max")
                            }
                          />
                        </td>
                      </div>
                      <div className="container-inputs-cabina">
                        <td className="doble-ssc-cabina-a">
                          <input
                            type="text"
                            className="none-ssc-cabina-a"
                            value={cabinaMin}
                            onChange={(e) =>
                              handleChangeSimple(e, subflota, turn, "Cabina", "min")
                            }
                          />
                        </td>
                        <td className="doble-ssc-cabina-r">
                          <input
                            type="text"
                            className="none-ssc-cabina-r"
                            value={cabinaMax}
                            onChange={(e) =>
                              handleChangeSimple(e, subflota, turn, "Cabina", "max")
                            }
                          />
                        </td>
                      </div>
                    </React.Fragment>
                  );
                }
                }

                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
  
        <div className="cf-buttons-cs">
          <div className="last-mod">
          <span className="bold-text"> Last modification made:{" "}
            {lastState ? (
              <Moment format="DD/MM/yyyy HH:mm" tz="UTC">
                {lastState}
              </Moment>
            ) : null}</span>
          </div>
          <Button
            variant="contained"
            classes={{ root: "vw-button" }}
            onClick={saveChanges}
            disableElevation
          >
            Apply changes to video wall
          </Button>
        </div>
  
        {/* Snackbar genérico */}
        {snackbar ? (
          <GenericSnackbar
            key={snackbar.date}
            severity={snackbar.severity}
            message={snackbar.msg}
          />
        ) : null}
      </div>
    );
}

export default CrewStandbyMaintainer;